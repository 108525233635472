<template>
  <div class="bg">
    <van-form @submit="onSubmit">
      <h1>个人信息</h1>
      <van-field
        v-model="from.username"
        name="姓名"
        label="姓名"
        placeholder="姓名"
        :rules="[{ required: true, message: '请填写姓名' }]"
      />
      <van-field
        v-model="from.username"
        name="手机号"
        label="手机号"
        placeholder="手机号"
        :rules="[{ required: true, message: '请填写手机号' }]"
      />
      <van-field
        v-model="from.sms"
        center
        clearable
        label="短信验证码"
        placeholder="请输入短信验证码"
      >
        <template #button>
          <van-button size="small" type="primary">发送验证码</van-button>
        </template>
      </van-field>

      <van-field
        v-model="from.orld"
        name="年龄"
        type="digit"
        label="年龄"
        placeholder="年龄"
        :rules="[{ required: true, message: '请填写年龄' }]"
      />
      <van-field name="radio" label="性别">
        <template #input>
          <van-radio-group v-model="from.sex" direction="horizontal">
            <van-radio name="1">男</van-radio>
            <van-radio name="2">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="from.username"
        name="身份证号码"
        label="身份证号码"
        placeholder="身份证号码"
        :rules="[{ required: true, message: '请填写身份证号码' }]"
      />
      <div class="upload">
        <p><span>上传身份证照片</span></p>
        <!-- <p>传检查报告或者患处图片，助医生更全面了解病情照片仅自己和医生可见</p> -->
        <van-uploader v-model="fileList" multiple />
      </div>
      <h1>求助信息</h1>
      <van-field
        v-model="from.username"
        name="求助标题"
        label="求助标题"
        placeholder="求助标题"
        :rules="[{ required: true, message: '请填写求助标题' }]"
      />
      <van-field
        v-model="from.username"
        name="筹款金额"
        label="筹款金额"
        placeholder="筹款金额(单位:元)"
        :rules="[{ required: true, message: '请填写筹款金额' }]"
      />
      <van-field
        v-model="from.message"
        rows="6"
        autosize
        label="求助说明"
        type="textarea"
        maxlength="500"
        placeholder="求助说明"
        show-word-limit
      />

      <div class="upload">
        <p><span>疾病病历</span>(最多9张图片)</p>

        <van-uploader v-model="fileList" multiple />
      </div>
      <div class="upload">
        <p><span>贫困证明</span>(最多9张图片)</p>

        <van-uploader v-model="fileList" multiple />
      </div>
      <van-radio-group v-model="radio" class="myradio" @change="changeradio">
        <van-radio name='1' >
          <span class="xy" @click="showxl"
            >确认则代表同意《时真公益救助协议》内容</span
          ></van-radio
        >
      </van-radio-group>
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"> 提交 </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { Toast } from "vant";
import { Dialog } from "vant";
export default {
  data() {
    return {
      from: {
        username: "",
        sex: "",
        orld: "",
        sms: "",
        message: "",
      },
      fileList: [],
      radio: "",
    };
  },
  methods: {
    onSubmit() {
      Toast("提示内容");
    },
    showxl() {
      Dialog.alert({
        title: "标题",
        message: "弹窗内容",
      }).then(() => {
        // on close
      });
    },
    changeradio(name) {

    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  background: #fff;
  .myradio .van-radio {
    padding-left: 16px;
  }
  .xy {
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #1daefc;
  }
  h1 {
    padding: 16px;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    text-align: left;
    color: #444444;
  }
}
.upload {
  text-align: left;
  color: #c1c4c6;
  padding: 16px;
  p {
    color: #c1c4c6;
    margin-bottom: 10px;
    span {
      color: #000;
      margin-right: 16px;
    }
  }
}
</style>
